import { createRouter, createWebHistory } from 'vue-router'

export default createRouter({
    history:createWebHistory(),
    routes: [
        {
            path:'/',
            component: () => import('@/pages/login.vue')
        },
        {
            path:'/question',
            component: () => import('@/pages/question.vue')
        },
        {
            path:'/final',
            component: () => import('@/pages/final.vue'),
            props({ query }) {
                return {finaltype:query.finaltype}
            }

        }
    ]
})